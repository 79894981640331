import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import MemberGenerateLink from './MemberGenerateLink';
import './members-list.css';
import { createMember, loadAll, removeMember } from './members.slice';
import MembersList from './MembersList';

export default function MembersListPage() {
    const { isLoading, loaded, error, list } = useAppSelector(
        (state) => state.members
    );

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setNewExternalId('');
        setShowModal(false);
    };
    const handleShowModal = () => setShowModal(true);

    const [newExternalId, setNewExternalId] = useState('');

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!isLoading && !loaded && !error) {
            dispatch(loadAll());
        }
    });

    const [generateLinkFor, setGenerateLinkFor] = useState(null);

    return (
        <React.Fragment>
            <MembersList
                list={list}
                editAction={true}
                deleteAction={(member: any) => {
                    dispatch(removeMember(member.id));
                }}
                generateLink={(member: any) => {
                    setGenerateLinkFor(member);
                }}
            />
            <Button
                className="new-button"
                variant="success"
                onClick={handleShowModal}
            >
                {t('actions.new')}
            </Button>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('actions.new')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group controlId="externalId">
                        <Form.Label>{t('entities.externalId')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={newExternalId}
                            onChange={(event: any) => {
                                setNewExternalId(event.target.value);
                            }}
                        />
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        {t('actions.close')}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            if (
                                newExternalId !== null &&
                                newExternalId.trim() !== ''
                            ) {
                                dispatch(createMember(newExternalId));
                            }
                            handleCloseModal();
                        }}
                    >
                        {t('actions.add')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={generateLinkFor !== null}
                onHide={() => {
                    setGenerateLinkFor(null);
                }}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('actions.generateLink')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <MemberGenerateLink memberData={generateLinkFor} />
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setGenerateLinkFor(null);
                        }}
                    >
                        {t('actions.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}
