import React from 'react';
import { FormControl } from 'react-bootstrap';

interface FilterProps {
    title: string;
    onChange: (name: string) => void;
}

export default function TableFilter(props: FilterProps) {
    return (
        <React.Fragment>
            <div>{props.title}</div>
            <div>
                <FormControl
                    placeholder=""
                    size="sm"
                    onChange={(event) => {
                        props.onChange(event.target.value);
                    }}
                />
            </div>
        </React.Fragment>
    );
}
