import { NotificationDisplayEntry } from './NotificationDisplayEntry';

export enum NotificationType {
    Alert = 'Alert',
    Error = 'Error',
}

export function createNotification(
    type: NotificationType,
    message: string,
    code = 0
): NotificationDisplayEntry {
    if (message === 'Bad Request') {
        message = 'error.badRequest';
    }

    return {
        id: Date.now(),
        timestamp: Date.now(),
        message,
        code,
        type,
    };
}
