import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config.json';

export type MembersState = {
    isLoading: boolean;
    loaded: boolean;
    error: boolean;
    list: any[];
    members: Record<number, any>;
};

const initialState: MembersState = {
    isLoading: false,
    loaded: false,
    error: false,
    list: [],
    members: {},
};

export const membersSlice = createSlice({
    name: 'members',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.loaded = false;
        },
        onLoadSuccess: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.loaded = true;
            state.list = action.payload;
        },
        onLoadOneSuccess: (state, action: PayloadAction<any>) => {
            state.members[action.payload.id] = action.payload;
        },
        onShouldReload: (state) => {
            state.loaded = false;
        },
        onLoadOneAvailableSurveysSuccess: (
            state,
            action: PayloadAction<any>
        ) => {
            state.members[action.payload.id] = {
                ...state.members[action.payload.id],
                availableSurveys: action.payload.availableSurveys,
            };
        },
        onGeneratedLinkSuccess: (state, action: PayloadAction<any>) => {
            state.members[action.payload.id] = {
                ...state.members[action.payload.id],
                linksForSurveys: {
                    ...state.members[action.payload.id].linksForSurveys,
                    [action.payload.surveyId]: action.payload.link,
                },
            };
        },
        onLoadFail: (state) => {
            state.isLoading = false;
            state.error = true;
        },
        onCreate: (state, action: PayloadAction<any>) => {
            state.list.push(action.payload);
        },
    },
});

export const {
    startLoading,
    onLoadSuccess,
    onLoadOneSuccess,
    onLoadOneAvailableSurveysSuccess,
    onGeneratedLinkSuccess,
    onLoadFail,
    onCreate,
    onShouldReload,
} = membersSlice.actions;

export const loadAll = createAsyncThunk(
    'members/loadAll',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const response = await axios.get(`${config.api.url}/members`);

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const loadOne = createAsyncThunk(
    'members/loadOne',
    async (memberId: number, thunkAPI) => {
        try {
            const response = await axios.get(
                `${config.api.url}/members/${memberId}`
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadOneSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const loadMemberWithAvailableSurveys = createAsyncThunk(
    'members/loadMemberAvailableSurveys',
    async (memberId: number, thunkAPI) => {
        try {
            const response = await axios.get(
                `${config.api.url}/members/${memberId}/availableSurveys`
            );

            if (response.status === 200) {
                thunkAPI.dispatch(
                    onLoadOneAvailableSurveysSuccess({
                        id: memberId,
                        availableSurveys: response.data,
                    })
                );
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const generateLinkForMemberSurvey = createAsyncThunk(
    'members/generateLinkForMemberSurvey',
    async (pair: any, thunkAPI) => {
        try {
            const response = await axios.get(
                `${config.api.url}/members/${pair.memberId}/generateToken/`,
                {
                    params: {
                        surveyId: pair.surveyId,
                    },
                }
            );

            if (response.status === 200) {
                thunkAPI.dispatch(
                    onGeneratedLinkSuccess({
                        id: pair.memberId,
                        surveyId: pair.surveyId,
                        link: `${config.clientUrl}?token=${encodeURIComponent(
                            response.data.token
                        )}`,
                    })
                );
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const removeMember = createAsyncThunk(
    'members/remove',
    async (memberId: number, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const response = await axios.delete(
                `${config.api.url}/members/${memberId}`
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const createMember = createAsyncThunk(
    'members/create',
    async (externalId: string, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const response = await axios.post(`${config.api.url}/members`, {
                externalId,
            });

            if (response.status === 201) {
                thunkAPI.dispatch(onCreate(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export type MemberOptions = {
    id?: number;
    externalId: string;
    firstName: string;
    lastName: string;
    email: string;
    telegram: string;
    patronymName: string;
    jobDepartment: string;
    jobPosition: string;
    jobFunction: string;
    jobTeam: string;
    canParticipate: boolean;
    canBeNotified: boolean;
};

export const saveMemberOptions = createAsyncThunk(
    'members/saveOptions',
    async (options: MemberOptions, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const id = options.id;
            delete options.id;

            const response = await axios.put(
                `${config.api.url}/members/${id}`,
                options
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadOneSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export default membersSlice.reducer;
