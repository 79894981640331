import React from 'react';
import { Table } from 'react-bootstrap';
import MemberListItem from './MemberListItem';
import { withTranslation } from 'react-i18next';
import './members-list.css';
import TableFilter from '../../components/TableFilter';

class MembersList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            filter: {},
        };
    }

    addFilter(field: string, value: string) {
        this.setState({
            filter: { [field]: value },
        });
    }

    renderFilter(field: string) {
        if (this.props.filter === true) {
            return (
                <TableFilter
                    title={this.props.t(`entities.${field}`)}
                    onChange={(value) => {
                        this.addFilter(field, value);
                    }}
                />
            );
        } else {
            return this.props.t(`entities.${field}`);
        }
    }

    render() {
        return (
            <Table className="members-list" bordered striped>
                <thead>
                    <tr>
                        <th
                            style={{
                                width: '1%',
                            }}
                        >
                            {this.props.t('lists.id')}
                        </th>
                        <th>{this.renderFilter('externalId')}</th>
                        <th>{this.renderFilter('firstName')}</th>
                        <th>{this.renderFilter('lastName')}</th>
                        <th>{this.renderFilter('email')}</th>
                        <th>{this.renderFilter('telegram')}</th>
                        <th>{this.renderFilter('jobDepartment')}</th>
                        <th>{this.renderFilter('jobPosition')}</th>
                        <th>{this.renderFilter('jobFunction')}</th>
                        <th>{this.renderFilter('jobTeam')}</th>
                        <th
                            style={{
                                width: '1%',
                            }}
                        >
                            {this.props.t('lists.actions')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.list
                        .filter((memberData: any) => {
                            for (const filterField in this.state.filter) {
                                const filerValue: string = this.state.filter[
                                    filterField
                                ]
                                    .toLowerCase()
                                    .trim();
                                if (
                                    !memberData[filterField]
                                        .toLowerCase()
                                        .trim()
                                        .includes(filerValue)
                                ) {
                                    return false;
                                }
                            }
                            return true;
                        })
                        .map((memberData: any) => {
                            return (
                                <MemberListItem
                                    id={memberData.id}
                                    key={memberData.id}
                                    memberData={memberData}
                                    deleteAction={this.props.deleteAction}
                                    addAction={this.props.addAction}
                                    editAction={this.props.editAction}
                                    generateLink={this.props.generateLink}
                                />
                            );
                        })}
                </tbody>
            </Table>
        );
    }
}

export default withTranslation()(MembersList);
