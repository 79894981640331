import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function MemberGroupListItem(props: any) {
    const { t } = useTranslation();

    return (
        <tr>
            <td>{props.id}</td>
            <td>{props.memberGroupData.title}</td>
            <td
                style={{
                    whiteSpace: 'nowrap',
                }}
            >
                {props.editAction ? (
                    <Button
                        variant="info"
                        as={NavLink}
                        to={`/member_groups/${props.id}`}
                    >
                        {t('actions.edit')}
                    </Button>
                ) : null}{' '}
                {props.deleteAction ? (
                    <Button
                        variant="danger"
                        onClick={() => {
                            if (confirm(t('edit.confirmationQuestion'))) {
                                props.deleteAction(props.memberGroupData);
                            }
                        }}
                    >
                        {t('actions.delete')}
                    </Button>
                ) : null}
                {props.addAction ? (
                    <Button
                        variant="success"
                        onClick={() => {
                            props.addAction(props.memberGroupData);
                        }}
                    >
                        {t('actions.add')}
                    </Button>
                ) : null}
            </td>
        </tr>
    );
}
