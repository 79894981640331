import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from '../../app/store';
import { NotificationDisplayEntry } from './NotificationDisplayEntry';
import './notification-display.css';
import { Alert } from 'react-bootstrap';
import { NotificationType } from './createNotification';

type State = {
    showingIds: number[];
    hideNotificationsIds: number[];
};

class NotificationDisplay extends React.Component<any, any> {
    state: State = {
        showingIds: [],
        hideNotificationsIds: [],
    };

    forgetNotification(id: number) {
        this.setState({
            hideNotificationsIds: [...this.state.hideNotificationsIds, id],
        });
    }

    componentDidUpdate() {
        const difference = this.props.notifications.filter(
            (notification: NotificationDisplayEntry) =>
                !this.state.showingIds.includes(notification.id)
        );

        if (difference.length > 0) {
            this.setState({
                showingIds: [
                    ...this.state.showingIds,
                    ...difference.map(
                        (notification: NotificationDisplayEntry) =>
                            notification.id
                    ),
                ],
            });

            difference.forEach((notification: NotificationDisplayEntry) => {
                const message = this.props.t(notification.message);
                const msPerLetter = 100;
                setTimeout(() => {
                    this.forgetNotification(notification.id);
                }, message.length * msPerLetter);
            });
        }
    }

    render() {
        return (
            <div className="notification-display">
                {this.props.notifications.map(
                    (notification: NotificationDisplayEntry, i: number) =>
                        !this.state.hideNotificationsIds.includes(
                            notification.id
                        ) ? (
                            <Alert
                                className="notification-display__item"
                                key={i}
                                onClick={() => {
                                    this.forgetNotification(notification.id);
                                }}
                                variant={
                                    notification.type === NotificationType.Error
                                        ? 'danger'
                                        : 'success'
                                }
                            >
                                {this.props.t(notification.message)}
                            </Alert>
                        ) : null
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    notifications: state.notifications.notifications,
});

export default withTranslation()(connect(mapStateToProps)(NotificationDisplay));
