import React from 'react';
import { Button, Form, Modal, Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RootState } from '../../app/store';
import {
    addMemberGroupMember,
    loadOne,
    removeMemberGroupMember,
    saveMemberGroupOptions,
} from './memberGroups.slice';
import './memberGroup-edit.css';
import MembersList from '../members/MembersList';
import { loadAll as loadAllMembers } from '../members/members.slice';
import { withTranslation } from 'react-i18next';

class MemberGroupEdit extends React.Component<any, any> {
    timeout: any = null;

    constructor(props: any) {
        super(props);
        this.state = {
            title: '',
            membersModalOpen: false,
        };
    }

    componentDidMount() {
        this.props.dispatch(loadOne(this.props.match.params.id));
    }

    handleTextChange(event: any) {
        this.setState({
            ...this.state,
            [event.target.id]: event.target.value,
        });
        this.saveChanges();
    }

    saveChanges() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const id: number = this.props.match.params.id;
            this.props.dispatch(
                saveMemberGroupOptions({
                    id,
                    title: this.state.title,
                })
            );
        }, 700);
    }

    openMembersModal() {
        this.props.dispatch(loadAllMembers());
        this.setState({
            membersModalOpen: true,
        });
    }

    render() {
        const id = this.props.match.params.id;
        if (this.props.memberGroups.memberGroups[id] === undefined) {
            return null;
        }

        const memberGroup: any = this.props.memberGroups.memberGroups[id];

        const memberGroupMembersIds = memberGroup.members.map((member: any) => {
            return member.id;
        });

        return (
            <div className="survey-edit">
                <Tabs defaultActiveKey="options">
                    <Tab
                        eventKey="options"
                        title={this.props.t('edit.options')}
                    >
                        <Form.Group controlId="title">
                            <Form.Label>
                                {this.props.t('entities.title')}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={this.state.title || memberGroup.title}
                                onChange={this.handleTextChange.bind(this)}
                            />
                        </Form.Group>
                    </Tab>
                    <Tab
                        eventKey="members"
                        title={this.props.t('menu.membersList')}
                    >
                        <MembersList
                            list={memberGroup.members}
                            deleteAction={(member: any) => {
                                this.props.dispatch(
                                    removeMemberGroupMember({
                                        memberGroupId: id,
                                        memberId: member.id,
                                    })
                                );
                            }}
                        />
                        <Button
                            className="new-button"
                            variant="success"
                            onClick={this.openMembersModal.bind(this)}
                        >
                            {this.props.t('edit.addMembersToGroup')}
                        </Button>
                    </Tab>
                </Tabs>
                <Modal
                    show={this.state.membersModalOpen}
                    dialogClassName="app-modal large"
                    onHide={() => {
                        this.setState({
                            membersModalOpen: false,
                        });
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.t('edit.addMembersToGroup')}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <MembersList
                            filter={true}
                            list={this.props.members.list.filter(
                                (member: any) => {
                                    return !memberGroupMembersIds.includes(
                                        member.id
                                    );
                                }
                            )}
                            addAction={(member: any) => {
                                this.props.dispatch(
                                    addMemberGroupMember({
                                        memberGroupId: id,
                                        memberId: member.id,
                                    })
                                );
                            }}
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                this.setState({
                                    membersModalOpen: false,
                                });
                            }}
                        >
                            {this.props.t('actions.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    members: state.members,
    memberGroups: state.memberGroups,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(MemberGroupEdit))
);
