import React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Login from './features/auth/Login';
import SurveysList from './features/surveys/SurveysList';
import './app.css';
import { Nav, Navbar } from 'react-bootstrap';
import SurveyEdit from './features/surveys/SurveyEdit';
import PrivateZone from './features/auth/PrivateZone';
import Logout from './features/auth/Logout';
import config from './config.json';
import { useAppSelector } from './app/hooks';
import { RootState } from './app/store';
import ManagersList from './features/managers/ManagersList';
import MemberGroupEdit from './features/memberGroups/MembersGroupEdit';
import MembersPage from './features/members/MembersPage';
import MemberGroupsPage from './features/memberGroups/MemberGroupsPage';
import MemberEdit from './features/members/MemberEdit';
import ManagerEdit from './features/managers/ManagerEdit';
import { useTranslation } from 'react-i18next';
import SurveyAnswers from './features/surveys/SurveyAnswers';
import NotificationDisplay from './components/NotificationDisplay/NotificationDisplay';

function App() {
    const { user } = useAppSelector((state: RootState) => state.auth);
    const { i18n, t } = useTranslation();

    return (
        <Router basename={config.baseUrl}>
            <div className="app">
                <NotificationDisplay />
                <Switch>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <Route>
                        <PrivateZone>
                            <Navbar bg="dark" variant="dark">
                                <Nav className="mr-auto">
                                    <Nav.Link as={NavLink} to="/surveys/">
                                        {t('menu.surveysList')}
                                    </Nav.Link>
                                    <Nav.Link as={NavLink} to="/member_groups">
                                        {t('menu.memberGroupsList')}
                                    </Nav.Link>
                                    <Nav.Link as={NavLink} to="/members">
                                        {t('menu.membersList')}
                                    </Nav.Link>
                                    {user !== null && user.canManageManagers ? (
                                        <Nav.Link as={NavLink} to="/managers">
                                            {t('menu.managersList')}
                                        </Nav.Link>
                                    ) : null}
                                </Nav>
                                <Navbar.Collapse className="justify-content-end">
                                    {i18n.language === 'ru' ? (
                                        <NavLink
                                            to="#"
                                            onClick={() => {
                                                i18n.changeLanguage('en');
                                            }}
                                        >
                                            English
                                        </NavLink>
                                    ) : null}
                                    {i18n.language === 'en' ? (
                                        <NavLink
                                            to="#"
                                            onClick={() => {
                                                i18n.changeLanguage('ru');
                                            }}
                                        >
                                            Русский
                                        </NavLink>
                                    ) : null}
                                    <Nav.Link as={NavLink} to="/logout">
                                        {t('logout')}
                                    </Nav.Link>
                                </Navbar.Collapse>
                            </Navbar>
                            <Switch>
                                <Route exact path="/">
                                    <Redirect
                                        to={{
                                            pathname:
                                                user !== null &&
                                                user.canManageSurveys
                                                    ? '/surveys'
                                                    : '/members',
                                        }}
                                    />
                                </Route>
                                <Route path="/logout">
                                    <Logout />
                                </Route>
                                <Route exact path="/members">
                                    <MembersPage />
                                </Route>
                                <Route path="/members/:id">
                                    <MemberEdit />
                                </Route>
                                <Route exact path="/member_groups">
                                    <MemberGroupsPage />
                                </Route>
                                <Route path="/member_groups/:id">
                                    <MemberGroupEdit />
                                </Route>
                                <Route exact path="/surveys">
                                    <SurveysList />
                                </Route>
                                <Route exact path="/surveys/:id">
                                    {user !== null && user.canManageSurveys ? (
                                        <SurveyEdit />
                                    ) : null}
                                </Route>
                                <Route exact path="/surveys/:id/answers">
                                    {user !== null && user.canSeeAnswers ? (
                                        <SurveyAnswers />
                                    ) : null}
                                </Route>
                                <Route exact path="/managers">
                                    {user !== null && user.canManageManagers ? (
                                        <ManagersList />
                                    ) : null}
                                </Route>
                                <Route path="/managers/:id">
                                    {user !== null && user.canManageManagers ? (
                                        <ManagerEdit />
                                    ) : null}
                                </Route>
                            </Switch>
                        </PrivateZone>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
