import React from 'react';
import {
    Button,
    ButtonGroup,
    Dropdown,
    DropdownButton,
    Form,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from '../../app/store';
import {
    distribute,
    DistributionMethod,
    DistributionStartMode,
    remove,
    saveSurveyOptions,
    saveSurveyStatusComment,
    SurveyStatus,
} from './surveys.slice';
import config from '../../config.json';
import { addNotification } from '../../components/NotificationDisplay/notificationDisplay.slice';
import {
    createNotification,
    NotificationType,
} from '../../components/NotificationDisplay/createNotification';

class SurveyListItem extends React.Component<any, any> {
    timeout: any = null;

    constructor(props: any) {
        super(props);

        this.state = {
            statusId: this.props.surveyData.statusId,
            currentComment: this.getStatusCommentValue(
                this.props.surveyData.statusId
            ),
            hiddenIframeSrc: '',
        };
    }

    saveCurrentComment() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.props.dispatch(
                saveSurveyStatusComment({
                    surveyId: this.props.id,
                    statusId: this.props.surveyData.statusId,
                    text: this.state.currentComment,
                })
            );
        }, 700);
    }

    downloadFile(url: string) {
        this.setState({
            hiddenIframeSrc:
                url +
                (url.indexOf('?') !== -1 ? '&' : '?') +
                `language=${this.props.i18n.language}&rand=` +
                Math.random(),
        });
    }

    getStatusCommentValue(statusId: number) {
        const currentComment = this.props.surveyData.surveyStatusComments.find(
            (comment: any) => comment.surveyStatusId === statusId
        );

        return currentComment ? currentComment.text : '';
    }

    distribute() {
        this.props.dispatch(
            distribute({
                surveyId: this.props.id,
                force: false,
                callback: (count) => {
                    if (
                        confirm(
                            this.props.t(
                                'edit.distributeEmailConfirmationQuestion',
                                { count }
                            )
                        )
                    ) {
                        this.props.dispatch(
                            distribute({
                                surveyId: this.props.id,
                                force: true,
                                callback: () => {
                                    this.props.dispatch(
                                        addNotification(
                                            createNotification(
                                                NotificationType.Alert,
                                                'notifications.emailDistributeSuccess'
                                            )
                                        )
                                    );
                                },
                            })
                        );
                    }
                },
            })
        );
    }

    render() {
        const survey = this.props.surveyData;

        const currentStatus = this.props.statuses.filter(
            (status: any) => status.id === survey.statusId
        );

        let canDistributeError: string | null = null;
        if (survey.distributionMethod !== DistributionMethod.Email) {
            canDistributeError = 'error.distribute.methodIsNotEmail';
        }

        if (
            ![
                DistributionStartMode.Absolute,
                DistributionStartMode.StartingFromAbsolute,
                DistributionStartMode.RepeatFromAbsolute,
            ].includes(survey.distributionStartMode)
        ) {
            canDistributeError = 'error.distribute.startModeIsNotAbsolute';
        }

        if (survey.startDate === null || survey.startDate > new Date()) {
            canDistributeError = 'error.distribute.startDateIsWrong';
        }

        if (
            currentStatus.length === 0 ||
            currentStatus[0].serveToMembers !== true
        ) {
            canDistributeError = 'error.distribute.statusIsWrong';
        }

        return (
            <React.Fragment>
                <tr>
                    <td>{this.props.id}</td>
                    <td>{survey.title}</td>

                    <td>
                        <div>
                            {this.props.auth.user !== null &&
                            this.props.auth.user.canModerateSurveys ? (
                                <React.Fragment>
                                    <Form.Control
                                        size="sm"
                                        as="select"
                                        custom
                                        value={this.state.statusId}
                                        onChange={(event) => {
                                            const statusId = parseInt(
                                                event.target.value,
                                                10
                                            );
                                            this.setState({
                                                statusId,
                                                currentComment: this.getStatusCommentValue(
                                                    statusId
                                                ),
                                            });
                                            this.props.dispatch(
                                                saveSurveyOptions({
                                                    id: this.props.id,
                                                    statusId: parseInt(
                                                        event.target.value,
                                                        10
                                                    ),
                                                })
                                            );
                                        }}
                                    >
                                        {this.props.statuses.map(
                                            (
                                                status: SurveyStatus,
                                                i: number
                                            ) => {
                                                return (
                                                    <option
                                                        value={status.id}
                                                        key={i}
                                                    >
                                                        {status.title}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Form.Control>
                                    <div>
                                        <Form.Control
                                            className="comment"
                                            type="text"
                                            size="sm"
                                            value={this.state.currentComment}
                                            placeholder={this.props.t(
                                                'entities.statusComment'
                                            )}
                                            onChange={(event) => {
                                                this.setState({
                                                    currentComment:
                                                        event.target.value,
                                                });
                                                this.saveCurrentComment();
                                            }}
                                        />
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div>
                                    <div>
                                        {this.props.statuses.length > 0 &&
                                        this.state.statusId !== null
                                            ? this.props.statuses.find(
                                                  (status: any) =>
                                                      status.id ===
                                                      this.state.statusId
                                              ).title
                                            : null}
                                    </div>
                                    <div>{this.state.currentComment}</div>
                                </div>
                            )}
                        </div>
                    </td>
                    <td
                        style={{
                            whiteSpace: 'nowrap',
                            textAlign: 'right',
                        }}
                    >
                        <Button
                            size="sm"
                            variant="success"
                            onClick={() => {
                                this.props.showImportModal(this.props.id);
                            }}
                        >
                            {this.props.t('actions.importMembers')}
                        </Button>{' '}
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 100, hide: 200 }}
                            trigger={
                                canDistributeError === null
                                    ? []
                                    : ['hover', 'focus']
                            }
                            overlay={
                                <Tooltip
                                    id={`tooltip_${this.props.id}_distribution_active`}
                                >
                                    {this.props.t(canDistributeError)}
                                </Tooltip>
                            }
                        >
                            <Button
                                size="sm"
                                placeholder="test"
                                variant={
                                    canDistributeError !== null
                                        ? 'dark'
                                        : 'info'
                                }
                                onClick={() => {
                                    if (canDistributeError === null) {
                                        this.distribute();
                                    } else {
                                        this.props.dispatch(
                                            addNotification(
                                                createNotification(
                                                    NotificationType.Error,
                                                    canDistributeError
                                                )
                                            )
                                        );
                                    }
                                }}
                            >
                                {this.props.t('actions.distribute')}
                            </Button>
                        </OverlayTrigger>{' '}
                        {this.props.auth.user !== null &&
                        this.props.auth.user.canSeeAnswers ? (
                            <React.Fragment>
                                <Button
                                    size="sm"
                                    variant="warning"
                                    as={NavLink}
                                    to={`/surveys/${this.props.id}/answers`}
                                >
                                    {this.props.t('actions.answers')}
                                </Button>{' '}
                            </React.Fragment>
                        ) : null}
                        <DropdownButton
                            as={ButtonGroup}
                            title={this.props.t('actions.export')}
                            id="bg-nested-dropdown"
                            size="sm"
                            variant="warning"
                        >
                            {this.props.auth.user !== null &&
                            this.props.auth.user.canSeeAnswers ? (
                                <Dropdown.Item
                                    onClick={() => {
                                        this.downloadFile(
                                            `${config.api.url}/surveys/${this.props.id}/answers?export=1`
                                        );
                                    }}
                                >
                                    {this.props.t('actions.exportAnswers')}
                                </Dropdown.Item>
                            ) : null}
                            {this.props.auth.user !== null &&
                            this.props.auth.user.canSeeAnswers &&
                            survey.distributionStartMode ===
                                DistributionStartMode.RepeatFromAbsolute ? (
                                <Dropdown.Item
                                    onClick={() => {
                                        this.props.showExportForMemberModal(
                                            survey.id
                                        );
                                    }}
                                >
                                    {this.props.t(
                                        'actions.exportRepeatAnswersForMember'
                                    )}
                                </Dropdown.Item>
                            ) : null}
                            <Dropdown.Item
                                onClick={() => {
                                    this.downloadFile(
                                        `${config.api.url}/surveys/${
                                            this.props.id
                                        }/members?export=1&baseUrl=${encodeURIComponent(
                                            config.clientUrl
                                        )}`
                                    );
                                }}
                            >
                                {this.props.t('actions.exportMembers')}
                            </Dropdown.Item>
                        </DropdownButton>{' '}
                        {this.props.auth.user !== null &&
                        this.props.auth.user.canManageSurveys ? (
                            <React.Fragment>
                                <Button
                                    size="sm"
                                    variant="info"
                                    as={NavLink}
                                    to={`/surveys/${this.props.id}`}
                                >
                                    {this.props.t('actions.edit')}
                                </Button>{' '}
                            </React.Fragment>
                        ) : null}
                        <Button
                            size="sm"
                            variant="danger"
                            onClick={() => {
                                if (
                                    confirm(
                                        this.props.t(
                                            'edit.confirmationQuestion'
                                        )
                                    )
                                ) {
                                    this.props.dispatch(remove(this.props.id));
                                }
                            }}
                        >
                            {this.props.t('actions.delete')}
                        </Button>
                        <iframe
                            src={this.state.hiddenIframeSrc}
                            style={{ display: 'none' }}
                        />
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
});

export default withTranslation()(connect(mapStateToProps)(SurveyListItem));
