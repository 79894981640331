import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RootState } from '../../app/store';
import { loadAnswers } from './surveys.slice';
import './answers.css';
import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

class SurveyAnswers extends React.Component<any, any> {
    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.dispatch(loadAnswers(id));
    }

    render() {
        const id = this.props.match.params.id;
        const answersData = this.props.surveys.answers[id];
        if (answersData === undefined) {
            return null;
        }

        return (
            <div className="answers">
                {answersData
                    .slice()
                    .sort((a: any, b: any) =>
                        a.questionId > b.questionId ? 1 : -1
                    )
                    .map((question: any, i: number) => {
                        let data = null;

                        const chartData = [];
                        switch (question.type) {
                            case 3:
                                data = (
                                    <div className="answers__data input">
                                        <ul>
                                            {Object.keys(question.answers).map(
                                                (answer: any, i: number) => (
                                                    <li key={i}>{answer}</li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                );
                                break;

                            default:
                                for (const answerText in question.answers) {
                                    chartData.push({
                                        name: answerText,
                                        value: question.answers[answerText],
                                    });
                                }

                                data = (
                                    <div className="answers__data chart">
                                        <ResponsiveContainer
                                            width="50%"
                                            height={chartData.length * 50 + 10}
                                        >
                                            <BarChart
                                                layout="vertical"
                                                maxBarSize={20}
                                                data={chartData}
                                                margin={{
                                                    left: 100,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis type="number" />
                                                <YAxis
                                                    type="category"
                                                    dataKey="name"
                                                    tick={{
                                                        fontSize: 14,
                                                        width: 130,
                                                    }}
                                                />
                                                <Tooltip />
                                                <Bar
                                                    dataKey="value"
                                                    fill="#3d3d3d"
                                                />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                );
                                break;
                        }

                        return (
                            <div key={i} className="answers__answer">
                                <h4>{question.text}</h4>
                                {data}
                            </div>
                        );
                    })}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    surveys: state.surveys,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(SurveyAnswers))
);
