import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Form, Modal, Nav, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, Redirect } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import formatDateTime from '../../utils/formatDateTime';
import SurveyListItem from './SurveyListItem';
import './surveys-list.css';
import {
    create,
    importMembersToSurvey,
    loadAll,
    onImportedDone,
} from './surveys.slice';
import config from '../../config.json';
import { addNotification } from '../../components/NotificationDisplay/notificationDisplay.slice';
import {
    createNotification,
    NotificationType,
} from '../../components/NotificationDisplay/createNotification';
import ExportForMemberModal from './ExportForMemberModal';

export default function SurveysList() {
    const {
        createdId,
        statuses,
        isLoading,
        loaded,
        error,
        list,
        importedCompleteMemberGroupId,
    } = useAppSelector((state) => state.surveys);

    const { t, i18n } = useTranslation();

    const [importModalOpened, setImportModalOpened] = useState(false);
    const [importSurveyId, setImportSurveyId] = useState(0);
    const [importMembersGroupTitle, setImportMembersGroupTitle] = useState('');
    const [importUpdateMembers, setImportUpdateMembers] = useState(false);
    const [
        exportForMemberModalOpened,
        setExportForMemberModalOpened,
    ] = useState(false);
    const [exportForMemberSurveyId, setExportForMemberSurveyId] = useState(0);

    const fileInput = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!isLoading && !loaded && !error) {
            dispatch(loadAll());
        }
    });

    return (
        <React.Fragment>
            {createdId !== null ? (
                <Redirect to={`/surveys/${createdId}`} />
            ) : (
                <React.Fragment>
                    <Table className="surveys-list" bordered striped>
                        <thead>
                            <tr>
                                <th style={{ width: '60px' }}>
                                    {t('lists.id')}
                                </th>
                                <th>{t('entities.title')}</th>
                                <th style={{ width: '350px' }}>
                                    {t('entities.status')}
                                </th>
                                <th
                                    style={{
                                        width: '1%',
                                    }}
                                >
                                    {t('lists.actions')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((surveyData: any) => (
                                <SurveyListItem
                                    id={surveyData.id}
                                    key={surveyData.id}
                                    statuses={statuses}
                                    surveyData={surveyData}
                                    showImportModal={(surveyId: number) => {
                                        setImportSurveyId(surveyId);
                                        setImportMembersGroupTitle(
                                            t(
                                                'texts.importMembersMemberGroupTitle',
                                                {
                                                    date: formatDateTime(
                                                        new Date()
                                                    ),
                                                    surveyId,
                                                }
                                            )
                                        );
                                        setImportModalOpened(true);
                                    }}
                                    showExportForMemberModal={(
                                        surveyId: number
                                    ) => {
                                        setExportForMemberSurveyId(surveyId);
                                        setExportForMemberModalOpened(true);
                                    }}
                                />
                            ))}
                        </tbody>
                    </Table>
                    <Button
                        className="new-button"
                        variant="success"
                        onClick={() => {
                            dispatch(create());
                        }}
                    >
                        {t('actions.new')}
                    </Button>
                </React.Fragment>
            )}
            <Modal
                size="lg"
                show={importModalOpened}
                onHide={() => {
                    setImportModalOpened(false);
                    dispatch(onImportedDone(null));
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('actions.importMembers')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group>
                        <Form.Label>
                            {t('edit.newMembersGroupTitle')}
                        </Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            value={importMembersGroupTitle}
                            onChange={(event) => {
                                setImportMembersGroupTitle(event.target.value);
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            type="checkbox"
                            id="updateMembers"
                            checked={importUpdateMembers}
                            label={t('edit.updateMembers')}
                            onChange={(event) => {
                                setImportUpdateMembers(event.target.checked);
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.File ref={fileInput} id="file" />
                    </Form.Group>
                    <Alert
                        style={{ marginTop: '10px' }}
                        show={importedCompleteMemberGroupId !== null}
                        variant="success"
                    >
                        <div>{t('texts.membersWereImported')}</div>
                        <a
                            className="nav-link"
                            href={`${
                                config.api.url
                            }/surveys/${importSurveyId}/members?export=1&baseUrl=${encodeURIComponent(
                                config.clientUrl
                            )}&language=${i18n.language}&rand=${Math.random()}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('actions.export')}
                        </a>
                        <Nav.Link
                            as={NavLink}
                            to={`/member_groups/${importedCompleteMemberGroupId}`}
                        >
                            {t('actions.goToMemberGroup')}
                        </Nav.Link>
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setImportModalOpened(false);
                            dispatch(onImportedDone(null));
                        }}
                    >
                        {t('actions.close')}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            if (
                                fileInput.current !== null &&
                                fileInput.current.files !== null &&
                                fileInput.current.files.length > 0
                            ) {
                                dispatch(
                                    importMembersToSurvey({
                                        language: i18n.language,
                                        surveyId: importSurveyId,
                                        memberGroupTitle: importMembersGroupTitle,
                                        updateMembers: importUpdateMembers,
                                        importFile: fileInput.current.files[0],
                                    })
                                );
                            } else {
                                dispatch(
                                    addNotification(
                                        createNotification(
                                            NotificationType.Error,
                                            'error.require_inputFile'
                                        )
                                    )
                                );
                            }
                        }}
                    >
                        {t('actions.importMembers')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <ExportForMemberModal
                list={list}
                exportForMemberModalOpened={exportForMemberModalOpened}
                setExportForMemberModalOpened={setExportForMemberModalOpened}
                exportForMemberSurveyId={exportForMemberSurveyId}
            />
        </React.Fragment>
    );
}
