import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import './member-groups-list.css';
import {
    createMemberGroup,
    loadAll,
    removeMemberGroup,
} from './memberGroups.slice';
import { useTranslation } from 'react-i18next';
import MemberGroupsList from './MemberGroupsList';

export default function MemberGroupsPage() {
    const { isLoading, loaded, error, list } = useAppSelector(
        (state) => state.memberGroups
    );

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setNewTitle('');
        setShowModal(false);
    };
    const handleShowModal = () => setShowModal(true);

    const [newTitle, setNewTitle] = useState('');

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!isLoading && !loaded && !error) {
            dispatch(loadAll());
        }
    });

    return (
        <React.Fragment>
            <MemberGroupsList
                list={list}
                editAction={true}
                deleteAction={(memberGroup: any) => {
                    dispatch(removeMemberGroup(memberGroup.id));
                }}
            />
            <Button
                className="new-button"
                variant="success"
                onClick={handleShowModal}
            >
                {t('actions.new')}
            </Button>
            <Modal show={showModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('actions.new')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group controlId="title">
                        <Form.Label>{t('entities.title')}</Form.Label>
                        <Form.Control
                            type="text"
                            value={newTitle}
                            onChange={(event: any) => {
                                setNewTitle(event.target.value);
                            }}
                        />
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        {t('actions.close')}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            if (newTitle !== null && newTitle.trim() !== '') {
                                dispatch(createMemberGroup(newTitle));
                            }
                            handleCloseModal();
                        }}
                    >
                        {t('actions.add')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}
