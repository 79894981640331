import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import {
    createNotification,
    NotificationType,
} from '../../components/NotificationDisplay/createNotification';
import { addNotification } from '../../components/NotificationDisplay/notificationDisplay.slice';
import config from '../../config.json';
import { onShouldReload as onMemberGroupsShouldReload } from '../memberGroups/memberGroups.slice';
import { onShouldReload as onMembersShouldReload } from '../members/members.slice';

export type SurveysState = {
    isLoading: boolean;
    loaded: boolean;
    error: boolean;
    list: any[];
    surveys: Record<number, any>;
    answers: Record<number, any>;
    statuses: SurveyStatus[];
    createdId: null | number;
    importedCompleteMemberGroupId: number | null;
};

export type SurveyStatus = {
    id: number;
    title: string;
};

const initialState: SurveysState = {
    isLoading: false,
    loaded: false,
    error: false,
    list: [],
    surveys: {},
    answers: {},
    statuses: [],
    createdId: null,
    importedCompleteMemberGroupId: null,
};

function convertDates(payloadData: any) {
    if (payloadData.startDate !== null) {
        payloadData.startDate = Date.parse(payloadData.startDate);
    }
    if (payloadData.distributionDate !== null) {
        payloadData.distributionDate = Date.parse(payloadData.distributionDate);
    }
}

export const surveysSlice = createSlice({
    name: 'surveys',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.loaded = false;
        },
        onLoadSuccess: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.loaded = true;

            action.payload.forEach((surveyData: any) => {
                convertDates(surveyData);
            });

            action.payload.forEach((surveyData: any) => {
                surveyData.questions.sort((a: any, b: any) => {
                    return a.id > b.id ? 1 : -1;
                });
            });

            state.list = action.payload;
            state.surveys = {};
            action.payload.forEach((survey: any) => {
                const id: number = survey.id;
                state.surveys[id] = survey;
            });
        },
        onStatusesLoadSuccess: (state, action: PayloadAction<any>) => {
            state.statuses = action.payload;
        },
        onLoadOneSuccess: (state, action: PayloadAction<any>) => {
            convertDates(action.payload);
            action.payload.questions.sort((a: any, b: any) => {
                return a.id > b.id ? 1 : -1;
            });

            const id = action.payload.id;
            state.surveys[id] = action.payload;
            const index = state.list.findIndex((survey) => survey.id === id);
            state.list[index] = action.payload;
        },
        onLoadAnswersSuccess: (state, action: PayloadAction<any>) => {
            state.answers[action.payload.surveyId] = action.payload.answers;
        },
        onCreate: (state, action: PayloadAction<any>) => {
            const newId: number = action.payload.id;
            state.createdId = newId;
            state.list.push(action.payload);
            state.surveys[newId] = action.payload;
        },
        onQuestionCreate: (state, action: PayloadAction<any>) => {
            state.surveys[action.payload.surveyId].questions.push(
                action.payload.questionData
            );
        },
        onCreatedStartEditing: (state) => {
            state.createdId = null;
        },
        onImportedDone: (state, action: PayloadAction<number | null>) => {
            state.importedCompleteMemberGroupId = action.payload;
        },
        onLoadFail: (state) => {
            state.isLoading = false;
            state.error = true;
        },
    },
});

export const {
    startLoading,
    onLoadSuccess,
    onStatusesLoadSuccess,
    onLoadFail,
    onLoadAnswersSuccess,
    onCreate,
    onCreatedStartEditing,
    onQuestionCreate,
    onLoadOneSuccess,
    onImportedDone,
} = surveysSlice.actions;

export const loadAll = createAsyncThunk(
    'surveys/loadAll',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const response = await axios.get(`${config.api.url}/surveys`);

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadSuccess(response.data.surveys));
                thunkAPI.dispatch(
                    onStatusesLoadSuccess(response.data.statuses)
                );
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const loadAnswers = createAsyncThunk(
    'surveys/loadAnswers',
    async (surveyId: number, thunkAPI) => {
        try {
            const response = await axios.get(
                `${config.api.url}/surveys/${surveyId}/answers`
            );

            if (response.status === 200) {
                thunkAPI.dispatch(
                    onLoadAnswersSuccess({
                        surveyId,
                        answers: response.data,
                    })
                );
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const remove = createAsyncThunk(
    'surveys/remove',
    async (surveyId: number, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const response = await axios.delete(
                `${config.api.url}/surveys/${surveyId}`
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export type SurveyQuestion = {
    surveyId: number;
    questionId: number;
};

export const removeQuestion = createAsyncThunk(
    'surveys/removeQuestion',
    async (ids: SurveyQuestion, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const response = await axios.delete(
                `${config.api.url}/surveys/${ids.surveyId}/questions/${ids.questionId}`
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const create = createAsyncThunk(
    'surveys/create',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const response = await axios.post(`${config.api.url}/surveys`);

            if (response.status === 201) {
                thunkAPI.dispatch(onCreate(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const createQuestion = createAsyncThunk(
    'surveys/createQuestion',
    async (surveyId: number, thunkAPI) => {
        try {
            const response = await axios.post(
                `${config.api.url}/surveys/${surveyId}/questions`
            );

            if (response.status === 201) {
                thunkAPI.dispatch(
                    onQuestionCreate({
                        surveyId,
                        questionData: response.data,
                    })
                );
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export enum DistributionMethod {
    Email = 'Email',
    Telegram = 'Telegram',
}

export enum DistributionStartMode {
    Absolute = 'Absolute',
    StartingFromAbsolute = 'StartingFromAbsolute',
    RepeatFromAbsolute = 'RepeatFromAbsolute',
    Relative = 'Relative',
}

export type SurveyOptions = {
    id?: number;
    title?: string;
    htmlHeader?: string;
    htmlFooter?: string;
    htmlComplete?: string;
    startDate?: Date;
    distributionMethod?: DistributionMethod;
    distributionStartMode?: DistributionStartMode;
    distributionDate?: DistributionStartMode;
    relativeDistributionDays?: number;
    distributionRepeatInterval?: number;
    showQuestionsOnOnePage?: boolean;
    canEditAnswers?: boolean;
};

export type SurveyStatusOptions = {
    id?: number;
    statusId: number;
};

export const saveSurveyOptions = createAsyncThunk(
    'surveys/saveOptions',
    async (surveyOptions: SurveyOptions | SurveyStatusOptions, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const id = surveyOptions.id;
            delete surveyOptions.id;

            const response = await axios.put(
                `${config.api.url}/surveys/${id}`,
                surveyOptions
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadOneSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export type StatusComment = {
    surveyId?: number;
    statusId?: number;
    text: string;
};

export const saveSurveyStatusComment = createAsyncThunk(
    'surveys/saveSurveyStatusComment',
    async (statusComment: StatusComment, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const surveyId = statusComment.surveyId;
            delete statusComment.surveyId;

            const statusId = statusComment.statusId;
            delete statusComment.statusId;

            const response = await axios.put(
                `${config.api.url}/surveys/${surveyId}/statuses/${statusId}`,
                statusComment
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadOneSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export type QuestionOptions = {
    id?: number;
    surveyId?: number;
    text: string;
    requiredAnswers: number;
    answers: string[];
    type: number;
};

export const saveQuestion = createAsyncThunk(
    'surveys/saveQuestion',
    async (questionOptions: QuestionOptions, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const surveyId = questionOptions.surveyId;
            const questionId = questionOptions.id;
            delete questionOptions.surveyId;
            delete questionOptions.id;

            const response = await axios.put(
                `${config.api.url}/surveys/${surveyId}/questions/${questionId}`,
                questionOptions
            );

            if (response.status === 200) {
                // saved
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export type SurveyMemberGroup = {
    surveyId: number;
    memberGroupId: number;
};

export const removeMemberGroup = createAsyncThunk(
    'surveys/removeMemberGroup',
    async (surveyMemberGroup: SurveyMemberGroup, thunkAPI) => {
        try {
            const response = await axios.delete(
                `${config.api.url}/surveys/${surveyMemberGroup.surveyId}/member_groups/${surveyMemberGroup.memberGroupId}`
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadOneSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const addMemberGroup = createAsyncThunk(
    'surveys/addMemberGroup',
    async (surveyMemberGroup: SurveyMemberGroup, thunkAPI) => {
        try {
            const response = await axios.post(
                `${config.api.url}/surveys/${surveyMemberGroup.surveyId}/member_groups`,
                {
                    memberGroupId: surveyMemberGroup.memberGroupId,
                }
            );

            if (response.status === 201) {
                thunkAPI.dispatch(onLoadOneSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export type SurveyImportMembers = {
    language: string;
    surveyId?: number;
    memberGroupTitle: string;
    updateMembers: boolean;
    importFile: File;
};

export const importMembersToSurvey = createAsyncThunk(
    'surveys/importMembers',
    async (dto: SurveyImportMembers, thunkAPI) => {
        try {
            const formData = new FormData();
            formData.append('memberGroupTitle', dto.memberGroupTitle);
            formData.append('updateMembers', JSON.stringify(dto.updateMembers));
            formData.append('importFile', dto.importFile);

            const response = await axios.post(
                `${config.api.url}/surveys/${dto.surveyId}/members?import=1&language=${dto.language}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.status === 201) {
                thunkAPI.dispatch(onImportedDone(response.data.id));
                thunkAPI.dispatch(onMemberGroupsShouldReload());
                thunkAPI.dispatch(onMembersShouldReload());
            } else {
                console.log(response);
            }
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                thunkAPI.dispatch(
                    addNotification(
                        createNotification(
                            NotificationType.Error,
                            error.response.data.message
                        )
                    )
                );
            } else {
                thunkAPI.dispatch(
                    addNotification(
                        createNotification(
                            NotificationType.Error,
                            'error.internet'
                        )
                    )
                );
            }
        }
    }
);

type DistributeData = {
    surveyId: number;
    callback: (count: number) => void;
    force: boolean;
};

export const distribute = createAsyncThunk(
    'surveys/distribute',
    async (data: DistributeData, thunkAPI) => {
        try {
            const response = await axios.post(
                `${config.api.url}/surveys/${data.surveyId}/distribute`,
                {
                    force: data.force,
                }
            );

            if (response.status === 201) {
                data.callback(response.data.count);
            } else {
                throw new Error();
            }
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                thunkAPI.dispatch(
                    addNotification(
                        createNotification(
                            NotificationType.Error,
                            error.response.data.message
                        )
                    )
                );
            } else {
                thunkAPI.dispatch(
                    addNotification(
                        createNotification(
                            NotificationType.Error,
                            'error.internet'
                        )
                    )
                );
            }
        }
    }
);

type UploadFileDto = {
    file: File;
    callback: (data: any) => void;
};

export const uploadFile = createAsyncThunk(
    'surveys/uploadFile',
    async (dto: UploadFileDto, thunkAPI) => {
        try {
            const formData = new FormData();
            formData.append('file', dto.file);

            const response = await axios.post(
                `${config.api.url}/upload/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.status === 201) {
                dto.callback(response.data);
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(
                addNotification(
                    createNotification(NotificationType.Error, 'error.upload')
                )
            );
        }
    }
);

export default surveysSlice.reducer;
