import React from 'react';
import { Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RootState } from '../../app/store';
import { loadOne, saveMemberOptions } from './members.slice';

class MemberEdit extends React.Component<any, any> {
    timeout: any = null;

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.dispatch(loadOne(this.props.match.params.id));
    }

    handleTextChange(event: any) {
        this.setState({
            ...this.state,
            [event.target.id]: event.target.value,
        });
        this.saveChanges();
    }

    handleCheckboxChange(event: any) {
        this.setState({
            ...this.state,
            [event.target.id]: event.target.checked,
        });
        this.saveChanges();
    }

    saveChanges() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const id: number = this.props.match.params.id;
            this.props.dispatch(
                saveMemberOptions({
                    id,
                    externalId: this.state.externalId,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email,
                    telegram: this.state.telegram,
                    patronymName: this.state.patronymName,
                    jobDepartment: this.state.jobDepartment,
                    jobPosition: this.state.jobPosition,
                    jobFunction: this.state.jobFunction,
                    jobTeam: this.state.jobTeam,
                    canParticipate: this.state.canParticipate,
                    canBeNotified: this.state.canBeNotified,
                })
            );
        }, 700);
    }

    render() {
        const id = this.props.match.params.id;
        const member: any = this.props.members.members[id];

        if (member === undefined) {
            return null;
        }

        return (
            <div className="manager-edit">
                <Form.Group controlId="externalId">
                    <Form.Label>
                        {this.props.t('entities.externalId')}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.externalId || member.externalId}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="firstName">
                    <Form.Label>
                        {this.props.t('entities.firstName')}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.firstName || member.firstName}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="lastName">
                    <Form.Label>{this.props.t('entities.lastName')}</Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.lastName || member.lastName}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="email">
                    <Form.Label>{this.props.t('entities.email')}</Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.email || member.email}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="telegram">
                    <Form.Label>{this.props.t('entities.telegram')}</Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.telegram || member.telegram}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="patronymName">
                    <Form.Label>
                        {this.props.t('entities.patronymName')}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.patronymName || member.patronymName}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="jobDepartment">
                    <Form.Label>
                        {this.props.t('entities.jobDepartment')}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.jobDepartment || member.jobDepartment}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="jobPosition">
                    <Form.Label>
                        {this.props.t('entities.jobPosition')}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.jobPosition || member.jobPosition}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="jobFunction">
                    <Form.Label>
                        {this.props.t('entities.jobFunction')}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.jobFunction || member.jobFunction}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="jobTeam">
                    <Form.Label>{this.props.t('entities.jobTeam')}</Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.jobTeam || member.jobTeam}
                        onChange={this.handleTextChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="canParticipate">
                    <Form.Check
                        type="checkbox"
                        label={this.props.t('entities.canParticipate')}
                        checked={
                            this.state.canParticipate !== undefined
                                ? this.state.canParticipate
                                : member.canParticipate
                        }
                        onChange={this.handleCheckboxChange.bind(this)}
                    />
                </Form.Group>
                <Form.Group controlId="canBeNotified">
                    <Form.Check
                        type="checkbox"
                        label={this.props.t('entities.canBeNotified')}
                        checked={
                            this.state.canBeNotified !== undefined
                                ? this.state.canBeNotified
                                : member.canBeNotified
                        }
                        onChange={this.handleCheckboxChange.bind(this)}
                    />
                </Form.Group>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    members: state.members,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(MemberEdit))
);
