import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config.json';

export type MemberGroupsState = {
    isLoading: boolean;
    loaded: boolean;
    error: boolean;
    list: any[];
    memberGroups: Record<number, any>;
};

const initialState: MemberGroupsState = {
    isLoading: false,
    loaded: false,
    error: false,
    list: [],
    memberGroups: {},
};

export const memberGroupsSlice = createSlice({
    name: 'memberGroups',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.loaded = false;
        },
        onLoadSuccess: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.loaded = true;
            state.list = action.payload;
        },
        onLoadOneSuccess: (state, action: PayloadAction<any>) => {
            state.memberGroups[action.payload.id] = action.payload;
        },
        onShouldReload: (state) => {
            state.loaded = false;
        },
        onLoadFail: (state) => {
            state.isLoading = false;
            state.error = true;
        },
        onCreate: (state, action: PayloadAction<any>) => {
            state.list.push(action.payload);
        },
    },
});

export const {
    startLoading,
    onLoadSuccess,
    onLoadFail,
    onCreate,
    onLoadOneSuccess,
    onShouldReload,
} = memberGroupsSlice.actions;

export const loadAll = createAsyncThunk(
    'memberGroups/loadAll',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const response = await axios.get(`${config.api.url}/member_groups`);

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const loadOne = createAsyncThunk(
    'memberGroups/loadOne',
    async (memberGroupId: number, thunkAPI) => {
        try {
            const response = await axios.get(
                `${config.api.url}/member_groups/${memberGroupId}`
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadOneSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const removeMemberGroup = createAsyncThunk(
    'memberGroups/remove',
    async (memberGroupId: number, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const response = await axios.delete(
                `${config.api.url}/member_groups/${memberGroupId}`
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const createMemberGroup = createAsyncThunk(
    'surveys/create',
    async (title: string, thunkAPI) => {
        thunkAPI.dispatch(startLoading());
        try {
            const response = await axios.post(
                `${config.api.url}/member_groups`,
                {
                    title,
                }
            );

            if (response.status === 201) {
                thunkAPI.dispatch(onCreate(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export type MemberGroupOptions = {
    id?: number;
    title: string;
};

export const saveMemberGroupOptions = createAsyncThunk(
    'surveys/saveMemberGroupOptions',
    async (memberGroupOptions: MemberGroupOptions, thunkAPI) => {
        try {
            const id = memberGroupOptions.id;
            delete memberGroupOptions.id;

            const response = await axios.put(
                `${config.api.url}/member_groups/${id}`,
                memberGroupOptions
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadOneSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export type MemberGroupMember = {
    memberGroupId: number;
    memberId: number;
};

export const removeMemberGroupMember = createAsyncThunk(
    'memberGroups/removeMember',
    async (memberGroupMember: MemberGroupMember, thunkAPI) => {
        try {
            const response = await axios.delete(
                `${config.api.url}/member_groups/${memberGroupMember.memberGroupId}/${memberGroupMember.memberId}`
            );

            if (response.status === 200) {
                thunkAPI.dispatch(onLoadOneSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export const addMemberGroupMember = createAsyncThunk(
    'memberGroups/addMember',
    async (memberGroupMember: MemberGroupMember, thunkAPI) => {
        try {
            const response = await axios.post(
                `${config.api.url}/member_groups/${memberGroupMember.memberGroupId}`,
                {
                    memberId: memberGroupMember.memberId,
                }
            );

            if (response.status === 201) {
                thunkAPI.dispatch(onLoadOneSuccess(response.data));
            } else {
                throw new Error();
            }
        } catch (error) {
            thunkAPI.dispatch(onLoadFail());
        }
    }
);

export default memberGroupsSlice.reducer;
