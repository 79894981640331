import React from 'react';
import { Button, Form, Modal, Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import './survey-edit.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { RootState } from '../../app/store';
import { withRouter } from 'react-router-dom';
import {
    addMemberGroup,
    createQuestion,
    DistributionMethod,
    DistributionStartMode,
    loadAll,
    onCreatedStartEditing,
    removeMemberGroup,
    saveSurveyOptions,
} from './surveys.slice';
import QuestionEdit from './QuestionEdit';
import MemberGroupsList from '../memberGroups/MemberGroupsList';
import { loadAll as loadAllMemberGroups } from '../memberGroups/memberGroups.slice';
import { withTranslation } from 'react-i18next';

class SurveyEdit extends React.Component<any, any> {
    timeout: any = null;

    constructor(props: any) {
        super(props);
        this.state = {
            memberGroupsModalOpen: false,
        };
    }

    componentDidMount() {
        if (this.props.surveys[this.props.match.params.id] === undefined) {
            this.props.dispatch(loadAll());
        }
        this.props.dispatch(onCreatedStartEditing());
    }

    handleTextChange(event: any) {
        this.setState({
            ...this.state,
            [event.target.id]: event.target.value,
        });
        this.saveChanges();
    }

    handleDateChange(target: string, date: Date) {
        this.setState({
            ...this.state,
            [target]: date,
        });
        this.saveChanges();
    }

    handleCheckboxChange(event: any) {
        this.setState({
            ...this.state,
            [event.target.id]: event.target.checked,
        });
        this.saveChanges();
    }

    saveChanges() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const id: number = this.props.match.params.id;
            this.props.dispatch(
                saveSurveyOptions({
                    id,
                    title: this.state.title,
                    htmlHeader: this.state.htmlHeader,
                    htmlFooter: this.state.htmlFooter,
                    htmlComplete: this.state.htmlComplete,
                    startDate: this.state.startDate,
                    distributionMethod: this.state.distributionMethod,
                    distributionStartMode: this.state.distributionStartMode,
                    distributionDate: this.state.distributionDate,
                    relativeDistributionDays: this.state
                        .relativeDistributionDays
                        ? parseInt(this.state.relativeDistributionDays, 10)
                        : undefined,
                    distributionRepeatInterval: this.state
                        .distributionRepeatInterval
                        ? parseInt(this.state.distributionRepeatInterval, 10)
                        : undefined,
                    showQuestionsOnOnePage: this.state.showQuestionsOnOnePage,
                    canEditAnswers: this.state.canEditAnswers,
                })
            );
        }, 700);
    }

    openMembersModal() {
        this.props.dispatch(loadAllMemberGroups());
        this.setState({
            memberGroupsModalOpen: true,
        });
    }

    render() {
        const id = this.props.match.params.id;
        if (this.props.surveys.surveys[id] === undefined) {
            return null;
        } else {
            const survey: any = this.props.surveys.surveys[id];

            let questions: any = [];

            if (survey.questions !== undefined) {
                questions = survey.questions.map(
                    (questionData: any, i: number) => {
                        return (
                            <div key={i}>
                                <QuestionEdit
                                    surveyId={id}
                                    data={questionData}
                                />
                            </div>
                        );
                    }
                );
            }

            const memberGroupsIds = survey.memberGroups.map(
                (memberGroup: any) => {
                    return memberGroup.id;
                }
            );

            return (
                <React.Fragment>
                    <div className="survey-edit">
                        <Tabs defaultActiveKey="options">
                            <Tab
                                eventKey="options"
                                title={this.props.t('edit.options')}
                            >
                                <Form.Group controlId="title">
                                    <Form.Label>
                                        {this.props.t('entities.title')}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={this.state.title || survey.title}
                                        onChange={this.handleTextChange.bind(
                                            this
                                        )}
                                    />
                                </Form.Group>
                                <Form.Group controlId="htmlHeader">
                                    <Form.Label>
                                        {this.props.t('entities.htmlHeader')}
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={
                                            this.state.htmlHeader ||
                                            survey.htmlHeader
                                        }
                                        onChange={this.handleTextChange.bind(
                                            this
                                        )}
                                    />
                                </Form.Group>
                                <Form.Group controlId="htmlFooter">
                                    <Form.Label>
                                        {this.props.t('entities.htmlFooter')}
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={
                                            this.state.htmlFooter ||
                                            survey.htmlFooter
                                        }
                                        onChange={this.handleTextChange.bind(
                                            this
                                        )}
                                    />
                                </Form.Group>
                                <Form.Group controlId="htmlComplete">
                                    <Form.Label>
                                        {this.props.t('entities.htmlComplete')}
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={
                                            this.state.htmlComplete ||
                                            survey.htmlComplete
                                        }
                                        onChange={this.handleTextChange.bind(
                                            this
                                        )}
                                    />
                                </Form.Group>
                                <Form.Group controlId="showQuestionsOnOnePage">
                                    <Form.Check
                                        type="checkbox"
                                        label={this.props.t(
                                            'entities.showQuestionsOnOnePage'
                                        )}
                                        checked={
                                            this.state
                                                .showQuestionsOnOnePage !==
                                            undefined
                                                ? this.state
                                                      .showQuestionsOnOnePage
                                                : survey.showQuestionsOnOnePage
                                        }
                                        onChange={this.handleCheckboxChange.bind(
                                            this
                                        )}
                                    />
                                </Form.Group>
                                <Form.Group controlId="canEditAnswers">
                                    <Form.Check
                                        type="checkbox"
                                        label={this.props.t(
                                            'entities.canEditAnswers'
                                        )}
                                        checked={
                                            this.state.canEditAnswers !==
                                            undefined
                                                ? this.state.canEditAnswers
                                                : survey.canEditAnswers
                                        }
                                        onChange={this.handleCheckboxChange.bind(
                                            this
                                        )}
                                    />
                                </Form.Group>
                                <Form.Group controlId="startDate">
                                    <Form.Label>
                                        {this.props.t('entities.startDate')}
                                    </Form.Label>
                                    <div>
                                        <DatePicker
                                            selected={
                                                this.state.startDate ||
                                                survey.startDate
                                            }
                                            onChange={this.handleDateChange.bind(
                                                this,
                                                'startDate'
                                            )}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="distributionMethod">
                                    <Form.Label>
                                        {this.props.t(
                                            'entities.distributionMethod'
                                        )}
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        custom
                                        value={
                                            this.state.distributionMethod ||
                                            survey.distributionMethod
                                        }
                                        onChange={this.handleTextChange.bind(
                                            this
                                        )}
                                    >
                                        {Object.values(DistributionMethod).map(
                                            (methodEnum: any, i: number) => {
                                                return (
                                                    <option
                                                        key={i}
                                                        value={methodEnum}
                                                    >
                                                        {this.props.t(
                                                            `entities.distributionMethods.${methodEnum}`
                                                        )}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="distributionStartMode">
                                    <Form.Label>
                                        {this.props.t(
                                            'entities.distributionStartMode'
                                        )}
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        custom
                                        value={
                                            this.state.distributionStartMode ||
                                            survey.distributionStartMode
                                        }
                                        onChange={this.handleTextChange.bind(
                                            this
                                        )}
                                    >
                                        {Object.values(
                                            DistributionStartMode
                                        ).map((methodEnum: any, i: number) => {
                                            return (
                                                <option
                                                    key={i}
                                                    value={methodEnum}
                                                >
                                                    {this.props.t(
                                                        `entities.distributionStartModes.${methodEnum}`
                                                    )}
                                                </option>
                                            );
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                {[
                                    DistributionStartMode.Absolute,
                                    DistributionStartMode.StartingFromAbsolute,
                                    DistributionStartMode.RepeatFromAbsolute,
                                ].includes(
                                    this.state.distributionStartMode ||
                                        survey.distributionStartMode
                                ) ? (
                                    <React.Fragment>
                                        <Form.Group controlId="distributionDate">
                                            <Form.Label>
                                                {this.props.t(
                                                    'entities.distributionDate'
                                                )}
                                            </Form.Label>
                                            <div>
                                                <DatePicker
                                                    selected={
                                                        this.state
                                                            .distributionDate ||
                                                        survey.distributionDate
                                                    }
                                                    onChange={this.handleDateChange.bind(
                                                        this,
                                                        'distributionDate'
                                                    )}
                                                />
                                            </div>
                                        </Form.Group>
                                        {(this.state.distributionStartMode ||
                                            survey.distributionStartMode) ===
                                        DistributionStartMode.RepeatFromAbsolute ? (
                                            <Form.Group controlId="distributionRepeatInterval">
                                                <Form.Label>
                                                    {this.props.t(
                                                        'entities.distributionRepeatInterval'
                                                    )}
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={
                                                        this.state
                                                            .distributionRepeatInterval ||
                                                        survey.distributionRepeatInterval
                                                    }
                                                    onChange={this.handleTextChange.bind(
                                                        this
                                                    )}
                                                />
                                            </Form.Group>
                                        ) : null}
                                    </React.Fragment>
                                ) : (
                                    <Form.Group controlId="relativeDistributionDays">
                                        <Form.Label>
                                            {this.props.t(
                                                'entities.relativeDistributionDays'
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={
                                                this.state
                                                    .relativeDistributionDays ||
                                                survey.relativeDistributionDays
                                            }
                                            onChange={this.handleTextChange.bind(
                                                this
                                            )}
                                        />
                                    </Form.Group>
                                )}
                            </Tab>
                            <Tab
                                eventKey="questions"
                                title={this.props.t('edit.questions')}
                            >
                                {questions}
                                <Form.Group controlId="required">
                                    <Button
                                        variant="success"
                                        onClick={() => {
                                            this.props.dispatch(
                                                createQuestion(id)
                                            );
                                        }}
                                    >
                                        {this.props.t('edit.addQuestion')}
                                    </Button>
                                </Form.Group>
                            </Tab>
                            <Tab
                                eventKey="memberGroups"
                                title={this.props.t('menu.memberGroupsList')}
                            >
                                <MemberGroupsList
                                    list={survey.memberGroups}
                                    deleteAction={(memberGroup: any) => {
                                        this.props.dispatch(
                                            removeMemberGroup({
                                                surveyId: survey.id,
                                                memberGroupId: memberGroup.id,
                                            })
                                        );
                                    }}
                                />
                                <Button
                                    className="new-button"
                                    variant="success"
                                    onClick={this.openMembersModal.bind(this)}
                                >
                                    {this.props.t(
                                        'edit.addMemberGroupToSurvey'
                                    )}
                                </Button>
                            </Tab>
                        </Tabs>
                        <Modal
                            size="xl"
                            show={this.state.memberGroupsModalOpen}
                            onHide={() => {
                                this.setState({
                                    memberGroupsModalOpen: false,
                                });
                            }}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {this.props.t(
                                        'edit.addMemberGroupToSurvey'
                                    )}
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <MemberGroupsList
                                    list={this.props.memberGroups.list.filter(
                                        (memberGroup: any) => {
                                            return !memberGroupsIds.includes(
                                                memberGroup.id
                                            );
                                        }
                                    )}
                                    addAction={(memberGroup: any) => {
                                        this.props.dispatch(
                                            addMemberGroup({
                                                surveyId: survey.id,
                                                memberGroupId: memberGroup.id,
                                            })
                                        );
                                    }}
                                />
                            </Modal.Body>

                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        this.setState({
                                            memberGroupsModalOpen: false,
                                        });
                                    }}
                                >
                                    {this.props.t('actions.close')}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = (state: RootState) => ({
    surveys: state.surveys,
    memberGroups: state.memberGroups,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(SurveyEdit))
);
