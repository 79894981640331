import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import config from '../../config.json';

export default function ExportForMemberModal(props: any) {
    const [selectedMemberId, setSelectedMemberId] = useState(0);
    const [hiddenIframeSrc, setHiddenIframeSrc] = useState('');

    const { t } = useTranslation();

    const members: any[] = [];
    if (props.exportForMemberSurveyId !== 0) {
        const exportingSurvey = props.list.find((survey: any) => {
            return survey.id === props.exportForMemberSurveyId;
        });
        if (exportingSurvey !== undefined) {
            for (const memberGroup of exportingSurvey.memberGroups) {
                members.push(...memberGroup.members);
            }
        }
    }

    return (
        <React.Fragment>
            <iframe src={hiddenIframeSrc} style={{ display: 'none' }} />
            <Modal
                size="lg"
                show={props.exportForMemberModalOpened}
                onHide={() => {
                    props.setExportForMemberModalOpened(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('actions.exportRepeatAnswersForMember')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t('texts.member')}</Form.Label>
                        <Form.Control
                            as="select"
                            htmlSize={10}
                            onChange={(event) => {
                                setSelectedMemberId(
                                    parseInt(event.target.value, 10)
                                );
                            }}
                            custom
                        >
                            {members.map((member, i) => {
                                return (
                                    <option key={i} value={member.id}>
                                        {member.externalId}
                                    </option>
                                );
                            })}
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            props.setExportForMemberModalOpened(false);
                        }}
                    >
                        {t('actions.close')}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setHiddenIframeSrc(
                                `${config.api.url}/surveys/${
                                    props.exportForMemberSurveyId
                                }/repeatedAnswers?export=1&memberId=${selectedMemberId}&rand=${Math.random()}`
                            );
                        }}
                    >
                        {t('actions.export')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}
