import React from 'react';
import { Table } from 'react-bootstrap';
import MemberGroupListItem from './MemberGroupListItem';
import { useTranslation } from 'react-i18next';
import './member-groups-list.css';

export default function MemberGroupsList(props: any) {
    const { t } = useTranslation();

    return (
        <Table className="member-groups-list" bordered striped>
            <thead>
                <tr>
                    <th style={{ width: '100px' }}>{t('lists.id')}</th>
                    <th>{t('entities.title')}</th>
                    <th
                        style={{
                            width: '1%',
                        }}
                    >
                        {t('lists.actions')}
                    </th>
                </tr>
            </thead>
            <tbody>
                {props.list.map((memberGroupData: any) => (
                    <MemberGroupListItem
                        id={memberGroupData.id}
                        key={memberGroupData.id}
                        memberGroupData={memberGroupData}
                        editAction={props.editAction}
                        deleteAction={props.deleteAction}
                        addAction={props.addAction}
                    />
                ))}
            </tbody>
        </Table>
    );
}
