import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationDisplayEntry } from './NotificationDisplayEntry';

export type NotificationDisplayState = {
    notifications: NotificationDisplayEntry[];
};

const initialState: NotificationDisplayState = {
    notifications: [],
};

export const notificationDisplaySlice = createSlice({
    name: 'notificationDisplay',
    initialState,
    reducers: {
        addNotification: (
            state,
            action: PayloadAction<NotificationDisplayEntry>
        ) => {
            state.notifications.push(action.payload);
        },
    },
});

export const { addNotification } = notificationDisplaySlice.actions;

export default notificationDisplaySlice.reducer;
