import React from 'react';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from '../../app/store';
import {
    loadMemberWithAvailableSurveys,
    generateLinkForMemberSurvey,
} from './members.slice';
import './member-generate-link.css';

class MemberGenerateLink extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { surveyId: null, wasCopied: false };
    }

    componentDidMount() {
        this.props.dispatch(
            loadMemberWithAvailableSurveys(this.props.memberData.id)
        );
    }

    generateLink(memberId: number, surveyId: number) {
        this.props.dispatch(
            generateLinkForMemberSurvey({
                memberId,
                surveyId,
            })
        );
    }

    copyToClipboard(text: string) {
        const dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        this.setState({
            wasCopied: true,
        });
    }

    render() {
        if (this.props.memberData === null) {
            return null;
        }

        const id = this.props.memberData.id;
        if (
            this.props.members.members[id] === undefined ||
            this.props.members.members[id].availableSurveys === undefined
        ) {
            return null;
        }

        const link =
            this.props.members.members[id].linksForSurveys !== undefined &&
            this.props.members.members[id].linksForSurveys[
                this.state.surveyId
            ] !== undefined
                ? this.props.members.members[id].linksForSurveys[
                      this.state.surveyId
                  ]
                : '';

        return (
            <React.Fragment>
                <Form.Group>
                    <Form.Control
                        as="select"
                        custom
                        onChange={(event) => {
                            const surveyId = parseInt(event.target.value, 10);
                            this.generateLink(id, surveyId);
                            this.setState({
                                surveyId,
                            });
                        }}
                    >
                        <option selected disabled>
                            {this.props.t('menu.surveysList')}
                        </option>
                        {this.props.members.members[id].availableSurveys.map(
                            (survey: any, i: number) => {
                                return (
                                    <option value={survey.id} key={i}>
                                        {survey.title}
                                    </option>
                                );
                            }
                        )}
                    </Form.Control>
                </Form.Group>
                {link !== '' ? (
                    <InputGroup>
                        <Form.Control
                            type="text"
                            placeholder="temp"
                            contentEditable={false}
                            value={link}
                        />
                        <InputGroup.Append>
                            <Button
                                variant="info"
                                onClick={() => {
                                    this.copyToClipboard(link);
                                }}
                            >
                                {this.props.t('actions.copy')}
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                ) : null}
                <Alert
                    style={{ marginTop: '10px' }}
                    show={this.state.wasCopied}
                    variant="success"
                >
                    {this.props.t('texts.wasCopied')}
                </Alert>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    members: state.members,
});

export default withTranslation()(connect(mapStateToProps)(MemberGenerateLink));
