import React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { removeQuestion, saveQuestion, uploadFile } from './surveys.slice';

class QuestionEdit extends React.Component<any, any> {
    timeout: any = null;
    fileUploadRef: any;

    constructor(props: any) {
        super(props);

        this.state = {
            ...this.props.data,
        };

        this.fileUploadRef = React.createRef();
    }

    uploadFile(callback: (path: string) => void) {
        this.fileUploadRef.current.onchange = () => {
            this.fileUploadRef.current.onchange = null;

            if (this.fileUploadRef.current.files[0] === undefined) {
                return;
            }

            this.props.dispatch(
                uploadFile({
                    file: this.fileUploadRef.current.files[0],
                    callback: (fileData) => {
                        callback(fileData.filePath);
                    },
                })
            );

            this.fileUploadRef.current.value = '';
        };
        this.fileUploadRef.current.click();
    }

    saveChanges() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.props.dispatch(
                saveQuestion({
                    surveyId: this.props.surveyId,
                    id: this.state.id,
                    text: this.state.text,
                    answers: this.state.answers,
                    type: this.state.type,
                    requiredAnswers: parseInt(this.state.requiredAnswers, 10),
                })
            );
        }, 700);
    }

    renderAnswers(answer: Record<string, string>, i: number) {
        return (
            <InputGroup key={`${this.state.id}_${i}`} className="mb-3">
                <Form.Control
                    size="sm"
                    id={`${this.state.id}_${i}`}
                    className="answer"
                    type="text"
                    value={answer.text}
                    onChange={(event) => {
                        const answers = [...this.state.answers];
                        answers[i] = {
                            ...answers[i],
                            text: event.target.value,
                        };
                        this.setState({
                            answers,
                        });
                        this.saveChanges();
                    }}
                />
                <InputGroup.Append>
                    {answer.imageLink === undefined ? (
                        <Button
                            variant="warning"
                            size="sm"
                            className="add-question"
                            onClick={() => {
                                this.uploadFile((path: string) => {
                                    const answers = [...this.state.answers];
                                    answers[i] = {
                                        ...answers[i],
                                        imageLink: path,
                                    };
                                    this.setState({
                                        answers,
                                    });
                                    this.saveChanges();
                                });
                            }}
                        >
                            {this.props.t('actions.upload')}
                        </Button>
                    ) : (
                        <Button
                            variant="warning"
                            size="sm"
                            className="add-question"
                            onClick={() => {
                                const answers = [...this.state.answers];
                                answers[i] = {
                                    ...answers[i],
                                    imageLink: undefined,
                                };
                                this.setState({
                                    answers,
                                });
                                this.saveChanges();
                            }}
                        >
                            {this.props.t('actions.removeUploaded')}
                        </Button>
                    )}
                    <Button
                        variant="danger"
                        size="sm"
                        className="add-question"
                        onClick={() => {
                            const answers = [...this.state.answers];
                            answers.splice(i, 1);
                            this.setState({
                                answers,
                            });
                            this.saveChanges();
                        }}
                    >
                        {this.props.t('actions.delete')}
                    </Button>
                </InputGroup.Append>
            </InputGroup>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Form.Group>
                    <Form.Label>{this.props.t('edit.question')}</Form.Label>
                    <Form.Control
                        size="sm"
                        type="text"
                        value={this.state.text}
                        onChange={(event) => {
                            this.setState({
                                text: event.target.value,
                            });
                            this.saveChanges();
                        }}
                    />
                </Form.Group>
                <Form.Group controlId="type">
                    <Form.Label>{this.props.t('edit.type')}</Form.Label>
                    <Form.Control
                        as="select"
                        value={this.state.type}
                        onChange={(event: any) => {
                            this.setState({
                                type: parseInt(event.target.value, 10),
                            });
                            this.saveChanges();
                        }}
                    >
                        <option value={1}>
                            {this.props.t('edit.types.radioGroup')}
                        </option>
                        <option value={4}>
                            {this.props.t('edit.types.radioGroupExtra')}
                        </option>
                        <option value={2}>
                            {this.props.t('edit.types.checkboxes')}
                        </option>
                        <option value={5}>
                            {this.props.t('edit.types.checkboxesExtra')}
                        </option>
                        <option value={3}>
                            {this.props.t('edit.types.input')}
                        </option>
                    </Form.Control>
                </Form.Group>
                {[1, 4, 2, 5].includes(this.state.type) ? (
                    <div>
                        <Form.Label>{this.props.t('edit.answers')}</Form.Label>
                        <Form.Group>
                            {this.state.answers.map(
                                this.renderAnswers.bind(this)
                            )}
                        </Form.Group>
                        <Button
                            variant="success"
                            size="sm"
                            className="add-question"
                            onClick={() => {
                                const answers = [...this.state.answers];
                                answers.push({ value: Date.now().toString() });
                                this.setState({
                                    answers,
                                });
                                this.saveChanges();
                            }}
                        >
                            {this.props.t('edit.addAnswer')}
                        </Button>
                    </div>
                ) : null}
                {[2, 5].includes(this.state.type) ? (
                    <Form.Group controlId="required">
                        <Form.Label>
                            {this.props.t('edit.requiredAnswers')}
                        </Form.Label>
                        <Form.Control
                            type="number"
                            value={this.state.requiredAnswers}
                            onChange={(event) => {
                                this.setState({
                                    requiredAnswers: event.target.value,
                                });
                                this.saveChanges();
                            }}
                        />
                    </Form.Group>
                ) : null}
                <Form.Group controlId="required">
                    <Button
                        variant="danger"
                        onClick={() => {
                            if (
                                confirm(
                                    this.props.t('edit.confirmationQuestion')
                                )
                            ) {
                                this.props.dispatch(
                                    removeQuestion({
                                        surveyId: this.props.surveyId,
                                        questionId: this.state.id,
                                    })
                                );
                            }
                        }}
                    >
                        {this.props.t('edit.deleteQuestion')}
                    </Button>
                </Form.Group>
                <input
                    type="file"
                    ref={this.fileUploadRef}
                    style={{ display: 'none' }}
                />
            </React.Fragment>
        );
    }
}

export default withTranslation()(connect()(QuestionEdit));
