import React from 'react';
import { Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function MemberListItem(props: any) {
    const { t } = useTranslation();

    return (
        <tr>
            <td>{props.id}</td>
            <td>{props.memberData.externalId}</td>
            <td>{props.memberData.firstName}</td>
            <td>{props.memberData.lastName}</td>
            <td>
                <a href={`mailto:${props.memberData.email}`}>
                    {props.memberData.email}
                </a>
            </td>
            <td>{props.memberData.telegram}</td>
            <td>{props.memberData.jobDepartment}</td>
            <td>{props.memberData.jobPosition}</td>
            <td>{props.memberData.jobFunction}</td>
            <td>{props.memberData.jobTeam}</td>
            <td
                style={{
                    whiteSpace: 'nowrap',
                }}
            >
                <DropdownButton
                    as={ButtonGroup}
                    title={t('lists.actions')}
                    drop="left"
                    id="bg-nested-dropdown"
                    variant="warning"
                >
                    {props.editAction ? (
                        <Dropdown.Item as={NavLink} to={`/members/${props.id}`}>
                            {t('actions.edit')}
                        </Dropdown.Item>
                    ) : null}
                    {props.generateLink ? (
                        <Dropdown.Item
                            onClick={() => {
                                props.generateLink(props.memberData);
                            }}
                        >
                            {t('actions.generateLink')}
                        </Dropdown.Item>
                    ) : null}
                    {props.deleteAction ? (
                        <Dropdown.Item
                            onClick={() => {
                                if (confirm(t('edit.confirmationQuestion'))) {
                                    props.deleteAction(props.memberData);
                                }
                            }}
                        >
                            {t('actions.delete')}
                        </Dropdown.Item>
                    ) : null}
                </DropdownButton>
                {props.addAction ? (
                    <Button
                        variant="success"
                        onClick={() => {
                            props.addAction(props.memberData);
                        }}
                    >
                        {t('actions.add')}
                    </Button>
                ) : null}
            </td>
        </tr>
    );
}
